import { add, isBefore } from 'date-fns';
import { Currency } from './constans';
import { defaultQueryReq } from '../features/hotelorders/ordersSlice';

// login token
const k_token = 'token';

export const GetToken = () => {
    return localStorage.getItem(k_token);
};

export const SaveToken = (token) => {
    return localStorage.setItem(k_token, token);
};

export const RemoveToken = () => {
    return localStorage.removeItem(k_token);
};

// login userInfo
const k_user_info = 'userInfo';

export const GetUserInfo = () => {
    return JSON.parse(localStorage.getItem(k_user_info)) || {};
};

export const SaveUserInfo = (data) => {
    return localStorage.setItem(k_user_info, JSON.stringify(data));
};

export const RemoveUserInfo = () => {
    return localStorage.removeItem(k_user_info);
};

// Channel user
export const IsChannelUser = () => {
    const userInfo = GetUserInfo();
    return !!userInfo?.agency?.channel;
};

export const ChannelID = () => {
    const userInfo = GetUserInfo();
    if (IsChannelUser() == false) {
        return '';
    }
    return userInfo.agency.channel.id;
};

export const FlightEnabled = () => {
    const userInfo = GetUserInfo();
    if (IsChannelUser() == false) {
        return true;
    }
    return userInfo.agency.channel.flightEnabled;
};

export const ActivityEnabled = () => {
    const userInfo = GetUserInfo();
    if (IsChannelUser() == false) {
        return true;
    }
    return userInfo.agency.channel.activityEnabled;
};

export const AccommodationEnabled = () => {
    const userInfo = GetUserInfo();
    if (IsChannelUser() == false) {
        return true;
    }
    return userInfo.agency.channel.accommodationEnabled;
};

// White label
export const WhiteLabelled = () => {
    const userInfo = GetUserInfo();
    if (IsChannelUser() == false) {
        return false;
    }
    return userInfo?.agency?.channel?.whiteLabelled;
};

export const WhiteLabelName = () => {
    const userInfo = GetUserInfo();
    if (IsChannelUser() == false) {
        return '';
    }
    return userInfo.agency.channel.whiteLabelName;
};

export const WhiteLabelServiceEmail = () => {
    const userInfo = GetUserInfo();
    if (IsChannelUser() == false) {
        return '';
    }
    return userInfo.agency.channel.whiteLabelEmail;
};

export const WhiteLabelLogoURL = () => {
    const userInfo = GetUserInfo();
    if (IsChannelUser() == false) {
        return '';
    }
    return userInfo.agency.channel.whiteLabelLogoURL;
};

export const WhiteLabelIconURL = () => {
    const userInfo = GetUserInfo();
    if (IsChannelUser() == false) {
        return '';
    }
    return userInfo.agency.channel.whiteLabelFaviconURL;
};

const k_currency = 'k_currency';

//Default CNY
export const GetCurrency = () => {
    return sessionStorage.getItem(k_currency) || Currency.CNY;
};

export const SaveCurrency = (currency) => {
    switch (currency) {
        case Currency.CNY:
            sessionStorage.setItem(k_currency, Currency.CNY);
            break;
        case Currency.AUD:
            sessionStorage.setItem(k_currency, Currency.AUD);
            break;
        default:
            break;
    }
};

// Flight search history
const k_flight_search_history = 'flight_search_history';

export const SaveToFlightSearchHistory = (query) => {
    let queries = GetFlightSearchQueryHistory();
    if (Array.isArray(queries) == false) {
        return;
    }
    const signature = (item) => {
        return JSON.stringify({
            toCityCode: item.toCityCode,
            fromCityCode: item.fromCityCode,
            tripType: item.tripType,
            fromDate: item.fromDate,
            retDate: item.retDate,
            cabinPref: item.cabinPref,
        });
    };
    queries = queries.filter((item) => {
        return signature(item) != signature(query);
    });
    queries = [query, ...queries];
    queries = queries.slice(0, 5); // Only keep 5 history
    localStorage.setItem(k_flight_search_history, JSON.stringify(queries));
};

export const GetFlightSearchQueryHistory = () => {
    const jsonString = localStorage.getItem(k_flight_search_history);
    let queries = JSON.parse(jsonString) ?? [];
    return queries;
};

export const CleanupFlightSearchQueryHistory = () => {
    return localStorage.removeItem(k_flight_search_history);
};

// air ticket data
const k_air_ticket_data = 'k_air_ticket_data';

export const SaveAirTicketData = (data) => {
    // Added timestamp in order to compare the freshness of the data
    const newData = { data: data, timestamp: Date.now() };
    sessionStorage.setItem(k_air_ticket_data, JSON.stringify(newData));
};

export const GetAirTicketData = () => {
    const jsonstring = sessionStorage.getItem(k_air_ticket_data);
    let json = JSON.parse(jsonstring) ?? {};
    if (json.timestamp) {
        const date = new Date(json.timestamp);
        const expiry = add(date, { hours: 1 });
        if (!isBefore(new Date(), expiry)) {
            RemoveAirTicketData();
        }
    }
    return json ?? {};
};

export const RemoveAirTicketData = () => {
    sessionStorage.removeItem(k_air_ticket_data);
};

// air ticket req
const k_air_ticket_req = 'k_air_ticket_req';

export const SaveAirTicketReq = (data) => {
    // Added timestamp in order to compare the freshness of the data
    const newData = { data: data, timestamp: Date.now() };
    sessionStorage.setItem(k_air_ticket_req, JSON.stringify(newData));
};

export const GetAirTicketReq = () => {
    const jsonstring = sessionStorage.getItem(k_air_ticket_req);
    let json = JSON.parse(jsonstring) ?? {};
    // Cleanup stale data
    if (json.timestamp) {
        const date = new Date(json.timestamp);
        const expiry = add(date, { hours: 1 });
        if (!isBefore(new Date(), expiry)) {
            RemoveAirTicketReq();
        }
    }
    delete json.timestamp;
    return json.data ?? {};
};

export const RemoveAirTicketReq = () => {
    sessionStorage.removeItem(k_air_ticket_req);
};

//TODO:
const k_hotel_search_history = 'hotel_search_history';

export const SaveToHotelSearchHistory = (query) => {
    let queries = GetHotelSearchQueryHistory();
    if (Array.isArray(queries) == false) {
        return;
    }
    const signature = (item) => {
        delete item['timestamp'];
        return item.cityCode + item.startDate + item.endDate;
    };
    queries = queries.filter((item) => {
        return signature(item) != signature(query);
    });
    queries = [query, ...queries];
    queries = queries.slice(0, 5); // Only keep 5 history
    localStorage.setItem(k_hotel_search_history, JSON.stringify(queries));
};

export const GetHotelSearchQueryHistory = () => {
    const jsonString = localStorage.getItem(k_hotel_search_history);
    let queries = JSON.parse(jsonString) ?? [];
    return queries;
};

// hotel avail data
const k_hotel_avail_data = 'k_hotel_avail_data';

export const SaveHotelAvailData = (data) => {
    // Added timestamp in order to compare the freshness of the data
    const newData = { data: data, timestamp: Date.now() };
    sessionStorage.setItem(k_hotel_avail_data, JSON.stringify(newData));
};

export const GetHotelAvailData = () => {
    const jsonstring = sessionStorage.getItem(k_hotel_avail_data);
    let data = JSON.parse(jsonstring) ?? {};
    if (data.timestamp) {
        const date = new Date(data.timestamp);
        const expiry = add(date, { hours: 1 });
        if (!isBefore(new Date(), expiry)) {
            RemoveHotelAvailData();
        }
    }
    return data.data ?? {};
};

export const GetHotelAvailDataTimestamp = () => {
    const jsonstring = sessionStorage.getItem(k_hotel_avail_data);
    let data = JSON.parse(jsonstring) ?? {};
    return data.timestamp;
};

export const RemoveHotelAvailData = () => {
    sessionStorage.removeItem(k_hotel_avail_data);
};

// Store hotel orders query params
const k_hotel_orders_query_req = 'k_hotel_orders_query_req';

export const SaveHotelOrdersQueryReq = (data) => {
    // if data is not object, store defuatlQueryReq
    if (typeof data !== 'object') {
        data = defaultQueryReq;
    }
    sessionStorage.setItem(k_hotel_orders_query_req, JSON.stringify(data));
};

export const GetHotelOrdersQueryReq = () => {
    const jsonString = sessionStorage.getItem(k_hotel_orders_query_req);
    let data = JSON.parse(jsonString) ?? defaultQueryReq;
    return data;
};
