export const BUILD_NUM = '20241013'; //must be a number

export let domain;
export let baseURL;
export let wsURL;
if (process.env.NODE_ENV !== 'production') {
    // Local development
    domain = '127.0.0.1:7100';
    baseURL = `http://${domain}`;
    wsURL = `ws://${domain}/ws`;
} else {
    // Production deploy
    domain = 'agency.aotravelconnect.com';
    baseURL = `https://${domain}`;
    wsURL = `wss://${domain}/ws`;
}
export const apiURL = `${baseURL}/api/v1`;
export const apiURL2 = `${baseURL}/api/v2`;

export let adminBaseURL;
if (process.env.NODE_ENV !== 'production') {
    // Local development
    adminBaseURL = `http://127.0.0.1:7101`;
} else {
    // Production deploy
    adminBaseURL = `https://admin.aotravelconnect.com`;
}
export let adminApiURL = `${adminBaseURL}/api/v1`;

//   {
//     "mail": "user@example.com",
//     "password": "password",
//     "Currency": "CNY",
//     "adultNumber": 1,
//     "childNumber": 0,
//     "company": "cq666",
//     "data": "D_07271033_5956DEA52724FCB4_1",
//     "kind": 1,
//     "totalPriceEx": 1025.99
//   }
//   ```

//   | 参数名 | 示例值 | 参数类型 | 是否必填 | 参数描述 |
//   | --- | --- | ---- | ---- | ---- |
//   | Currency | CNY | String | 是 | 币种 |
//   | adultNumber | 1 | Integer | 是 | 成人人数 |
//   | childNumber | 0 | Integer | 是 | 儿童人数 |
//   | company | cq666 | String | 是 | 旅行社标识 |
//   | data | D_07271033_5956DEA52724FCB4_1 | String | 是 | 航线唯一标识 |
//   | kind | 1 | Integer | 是 | 去程1往返2 |
//   | totalPriceEx | 1025.99 | Number | 是 | 总金额 |
//   | mail | user@example.com | String | 是 | 邮箱 |
//   | password | password | String | 是 | 密码 |
