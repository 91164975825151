import { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as config from '@config';
import { GetToken, SaveAirTicketData, GetAirTicketData, SaveAirTicketReq } from '@utils/storage';

function useFlightSearch(props) {
    // fetching
    const [fetching, setFetching] = useState(false);
    // fetched
    const [fetched, setFetched] = useState(false);
    // timestamp
    const [timestamp, setTimestamp] = useState(null);
    // data
    const [data, setData] = useState({});
    const abortControllerRef = useRef(null);

    // The fetch API doesn't natively support progress tracking, and timeout.
    const fetchFlights = useCallback(async (req) => {
        console.log(req);
        setFetching(true);
        setFetched(false);
        setData({});
        abortControllerRef.current = new AbortController();
        try {
            const response = await axios.post(`${config.apiURL2}/flights`, req, {
                // timeout: 45 * 1000, //45 seconds
                signal: abortControllerRef.current.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
                onDownloadProgress: (progressEvent) => {
                    const total = progressEvent.event.srcElement.getResponseHeader('content-length');
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / total);
                    console.log('total:', total);
                    console.log(percentCompleted + '%');
                },
            });
            if (response.status < 200 || response.status >= 300) {
                // Response is OK
                console.log(response.data);
                throw new Error(response.data.error || 'An error occurred while fetching flight orders');
            }
            const data = response.data;
            // console.log(data);
            if (data) {
                setData(data);
                setTimestamp(Date.now());
                SaveAirTicketData(data);
                SaveAirTicketReq(req);
                // console.log(data);
                console.log('Find number of routes:', data.routings.length);
            }
            return data;
        } catch (error) {
            // Handle errors
            console.log(error);
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                console.log('Request timed out');
                alert('系统超时，请稍后再试');
                return new Error('Request timed out');
            } else {
                return new Error(error);
            }
        } finally {
            setFetching(false);
            setFetched(true);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(data).length === 0) {
            // console.log('get flights data from storage');
            const data = GetAirTicketData();
            // NOTE: avoild set data to null
            setData(data.data || {});
            setTimestamp(data.timestamp || null);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, fetched, data, timestamp, fetchFlights, abort };
}

useFlightSearch.propTypes = {};

export default useFlightSearch;
