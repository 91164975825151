import { createContext, useContext, useState } from 'react';
import { defaultData } from '../components/air/AirSearchForm';

const MyContext = createContext(null);

export function MyProvider({ children }) {
    //Global state to make the search form data available to all components
    const [flightSearchReq, setFlightSearchReq] = useState(defaultData);

    const value = {
        flightSearchReq,
        setFlightSearchReq,
    };

    return <MyContext.Provider value={value}>{children}</MyContext.Provider>;
}

export const useMyContext = () => useContext(MyContext);
